import React from 'react'
import styled from 'styled-components';
import Cta from './Cta'
import HeroWeb from './HeroWeb'
import Intro from './Intro'
import Request from '../Request'
import Webprocess from './Webprocess'
import InfoCard from './InfoCard'

const Body = () => {
    return (
        <Container>
            <CtaWrapper>
                <Cta />
            </CtaWrapper>
            <Hero>
                <HeroWeb />
            </Hero>
            <IntroWrapper>
                <Intro />
            </IntroWrapper>
            <GetCard>
                <InfoCard />
            </GetCard>
            <ImageCard>
                <Webprocess />
            </ImageCard>
            <RequestCard><Request /></RequestCard>
        </Container>
    )
}

export default Body

const Container = styled.div`
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns: repeat(12,1fr);
    grid-auto-rows: auto;   
    
`
const CtaWrapper = styled.div`
    grid-row: 2;
    grid-column: span 12;
                
    @media screen and (min-width: 1000px) {
        grid-row: 1;
        grid-column: span 4;
    }
`
const Hero = styled.div`
    grid-column: span 12;
    

    @media screen and (min-width: 1000px) {
        grid-column: span 8;
       
    }

`
const IntroWrapper = styled.div`
    grid-column: span 12;
    background: #fff;

`
const GetCard = styled.div`
    grid-column: span 12;
    background: #E5E5E5;
    

    @media screen and (min-width: 600px) {
        grid-column: span 6;
        background: #E5E5E5;
    }

    @media screen and (min-width: 1000px) {
        grid-column: span 4;
        background: #E5E5E5;
        grid-row: span 2;
    }
`
const ImageCard = styled.div`
    grid-column: span 12;
    background: #E5E5E5;

    @media screen and (min-width: 600px) {
        grid-column: span 6;
        background: #E5E5E5;
        grid-row: span 2;
    }

    @media screen and (min-width: 1000px) {
        grid-column: span 4;
        background: #E5E5E5;
    }
`
const RequestCard = styled.div`
    grid-column: span 12;
    background: #E5E5E5;
    
        
    @media screen and (min-width: 600px) {
        grid-column: span 6;
        background: #E5E5E5;
    }

    @media screen and (min-width: 1000px) {
        grid-column: span 4;
        background: #E5E5E5;
        grid-row: span 2;
      }
`
