import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Body from '../components/website/Body'

const Website = () => (
    <Layout>
        <SEO title="Web design kenya and web hosting in kenya"
            description="We offer web design and development in Kenya, we build fast, affordable and intuitive websites for healthcare businesses with free web hosting."
            keywords=" website, web design in kenya, web design kenya, kenya web design, web hosting kenya, web hosting in kenya, kenya web hosting, graphic design, kenya logo, Web development in Nairobi Kenya, web design in Nairobi Kenya, website for health in Nairobi Kenya, medical website, healthcare website design in Nairobi Kenya, affordable web design in Nairobi Kenya, website design in kenya, website design kenya"
            img="https://www.tripleaimsoftware.com/static/238d329750325a36a4708862c3d4675e/ee604/HeroWeb.png"
            siteUrl="https://www.tripleaimsoftware.com/website/">
        </SEO>
        <Body />
    </Layout>
)

export default Website
